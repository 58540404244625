<template>
    <div class="control">
        <div class="home-control">
            <div class="swiper-control">
                <van-skeleton class="swiper-box" :row="1" :loading="loading"/>

                <swiper class="swiper-box" ref="mySwiper" :options="swiperOption" v-if="!loading">
                    <swiper-slide v-for="(item,index) in dataList" :key="index">
                        <div class="swiper-item">
                            <img :src="item.banner" class="swiper-item-bg"/>
                            <div class="swiper-item-con">
                                <div class="swiper-item-state">已激活</div>
                                <div class="swiper-item-bottom flex-between">
                                    <div></div>
                                    <div class="bottom-time">
                                        {{ item.can_use_end | setTime }} 到期
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div class="interests-tip" v-if="dataDetail.card_status === 2">
                <van-skeleton :loading="loading" title>
                    * 您的权益卡处于等待期 {{ dataDetail.wait_date }}
                </van-skeleton>

            </div>
        </div>

        <div class="interests-view">
            <div class="flex-between interests-person">
                <div class="flex-center">
                    <img src="https://keyang2.tuzuu.com/uuo/ydj/b9c5c64a251e83b7f516ad209be3023b.png"/>
                    <div>
                        <div class="name-1">权益人</div>
                        <div class="name-2">{{ dataDetail.name }}</div>
                    </div>
                </div>
                <div class="btn" @click="showCnm = true">
                    更多信息
                </div>
            </div>
            <div class="interests-list">
                <div class="interests-title">
                    <span style="position: relative">权益展示</span>
                </div>
                <div v-for="item in dataDetail.power_detail" :key="item.id" v-html="item.detail"
                     class="interests-item-box" @click="openDetail">
                </div>
            </div>
        </div>

        <router-link class="shab" to="/ydj/person-center">
            <img src="https://keyang2.tuzuu.com/uuo/ydj/2f8a12e9dbf7bfb6b83dde950a3b7c7b.png"/>
            <span>个人中心</span>
        </router-link>

        <van-popup v-model="showCnm" round class="detail-control" position="bottom">
            <van-tabs v-model:active="tabActive" class="detail-tab">
                <van-tab title="基本信息">
                    <div class="person-detail">
                        <div class="flex module-title">
                            <img src="https://keyang2.tuzuu.com/uuo/ydj/e170a4336cf5793fb74a867f02c7f018.png"/>
                            <span>权益详情</span>
                        </div>
                        <div class="person-box">
                            <div class="detail-item flex">
                                <div class="label">姓名</div>
                                <div class="value">{{ dataDetail.name }}</div>
                            </div>
                            <div class="detail-item flex">
                                <div class="label">证件号</div>
                                <div class="value">{{ dataDetail.license_no }}</div>
                            </div>
                            <div class="detail-item flex">
                                <div class="label">手机号</div>
                                <div class="value">{{ dataDetail.tel }}</div>
                            </div>
                            <div class="detail-item flex">
                                <div class="label">首次激活</div>
                                <div class="value">{{ dataDetail.created_at }}</div>
                            </div>
                            <div class="detail-item flex">
                                <div class="label">最长有效期</div>
                                <div class="value">{{ dataDetail.can_use_end }}</div>
                            </div>

                        </div>
                    </div>
                </van-tab>
                <van-tab title="药品目录">
                    <div class="drug-box">
                        <div class="flex module-title">
                            <img src="https://keyang2.tuzuu.com/uuo/ydj/398c16deb9f4757cd14049d2e5682c1c.png"/>
                            <span>特药权益卡药品目录</span>
                        </div>
                        <div class="drug-info">
                            {{ drugList.length }}种特药药品目录
                        </div>
                        <div class="drug-table">
                            <div class="drug-tr drug-header">
                                <div class="drug-td">序号</div>
                                <div class="drug-td">商品名</div>
                                <div class="drug-td">适应疾病</div>
                            </div>
                            <div class="drug-tr" v-for="(item,index) in drugList" :key="index">
                                <div class="drug-td">{{ index + 1 }}</div>
                                <div class="drug-td">{{ item.goods_name }}</div>
                                <div class="drug-td">{{ item.indications }}</div>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </van-popup>


        <van-popup v-model="kefuShow" closeable round position="bottom">
            <div class="kefu">
                <p style="margin-bottom: 30px">长按识别客服二维码</p>
                <van-image width="65%" fit="contain"
                           src="https://keyang2.tuzuu.com/uuo/ydj/582f81ff944764c3914b60aa601d5d72.jpg"/>
            </div>
        </van-popup>
    </div>

</template>

<script>
import {Toast} from "vant";
import {swiper, swiperSlide} from "vue-awesome-swiper";
import 'swiper/dist/css/swiper.css'
import {formatDate} from "@/utils/util";
import wxShare from "@/wechat/weChatTile";

let that, _that
export default {
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            tabActive: 0,
            showCnm: false,
            loading: true,
            kefuShow: false,
            swiperOption: {
                //每张播放时长3秒，自动播放
                spaceBetween: 10,
                // 切换效果
                effect: "coverflow",
                // 该选项给Swiper用户提供小小的贴心应用，设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状。
                grabCursor: true,
                // 设定为true时，active slide会居中，而不是默认状态下的居左。
                centeredSlides: true,
                // 设置slider容器能够同时显示的slides数量(carousel模式)。
                slidesPerView: 1.12,
                on: {
                    slideChange: function () {
                        let index = that.activeIndex
                        _that.dataDetail = _that.dataList[index]
                    }
                }
            },
            dataList: [],
            dataDetail: [],
            drugList: []
        }
    },
    created() {
        Promise.all([
            axios.get("/ydj/get_my_power_card"),
            axios.get("/ydj/get_medicine")
        ]).then((res) => {
            if (res[0].data.length === 0) {
                this.$router.push("/ydj")
                return
            }
            this.dataList = res[0].data
            this.dataDetail = this.dataList[0]
            this.drugList = res[1].data
            this.loading = false
            setTimeout(() => {
                if (this.$refs.mySwiper.swiper) {
                    _that = this
                    that = this.$refs.mySwiper.swiper
                }

            }, 500)
            this.$nextTick(() => {
                let btnList = document.querySelectorAll(".interests-btn")
                btnList.forEach(item => {
                    item.addEventListener("click", (e) => {
                        let btnDom = e.currentTarget
                        let href = btnDom.getAttribute("data-src")
                        if (href) {
                            e.stopPropagation()
                            window.location.href = href
                        } else {

                        }

                    })
                })
            })
        })
    },
    mounted() {
        wxShare({
            title: '一站式健康管理服务平台',
            desc: '康来家园，中国健康管理领导者',
            link: window.location.origin + '/ydj',
        })
    },
    methods: {
        openDetail() {
            console.log(this.$refs.mySwiper?.swiper)
            if (this.dataDetail.card_status === 2) {
                Toast("您的权益卡正处于等待期")
            } else if (this.dataDetail.card_status === 3) {
                Toast("您的权益卡已过期")
            } else if (this.dataDetail.card_status === 1) {
                this.kefuShow = true
            }
        },
    },
    filters: {
        setTime(value) {
            return formatDate(value, 'YYYY-MM-DD')
        }
    }
}


</script>

<style scoped lang="less">
.control {
    background: #F8F6F9;
    min-height: 100vh;

    /deep/ .flex-align {
        display: flex;
        align-items: center;
    }

    .home-control {
        background-image: url("https://keyang2.tuzuu.com/uuo/ydj/0b7d4105e4c81023c3d862653154bd5e.png");
        background-size: 100%;
    }

    .swiper-control {
        padding: 20px 0 0px;

        height: fit-content;

        .swiper-box {
            padding-top: 59%;
            position: relative;

            /deep/ .swiper-wrapper {
                position: absolute;
                top: 7px;
            }

            /deep/ .van-skeleton__content {
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                padding: 0 20px;
                box-sizing: border-box;
                width: 100%;

                .van-skeleton__row {
                    width: 100% !important;
                    height: 100%;
                }
            }
        }

        .swiper-item {
            position: relative;

            .swiper-item-bg {
                width: 100%;
            }

            .swiper-item-con {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                .swiper-item-state {
                    position: absolute;
                    top: -5px;
                    right: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #222037;
                    line-height: 20px;
                    padding: 4px 5px;
                    background: #FFE3D1;
                    border-radius: 2px;

                    &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 6px;
                        height: 6px;
                        left: -6px;
                        top: 1px;
                        background: #C2A490;
                        clip-path: polygon(100% 0, 100% 100%, 0 100%);
                    }
                }

                .swiper-item-bottom {
                    position: absolute;
                    bottom: 10%;
                    padding: 0 12px 0 15px;
                    width: 100%;
                    box-sizing: border-box;

                    .bottom-time {
                        font-size: 12px;
                        font-weight: 500;
                        color: rgba(255, 255, 255, 0.5);
                        line-height: 17px;
                    }
                }
            }
        }
    }

    .interests-tip {
        margin-top: 9px;
        font-size: 13px;
        text-align: center;
        color: #5F655F;
        line-height: 19px;

        /deep/ .van-skeleton__title {
            margin: auto;
            width: 70% !important;
        }
    }

    .interests-view {
        margin: 15px 10px 0;
        background-color: white;
        position: relative;
        background-image: url("https://keyang2.tuzuu.com/uuo/ydj/6645ed299ba3b8ff2ca227ac81807305.png");
        background-size: 100%;
        background-repeat: no-repeat;

        .interests-person {
            padding: 19px 20px 40px;
            position: relative;
            z-index: 5;

            img {
                width: 22px;
                margin-right: 10px;
            }

            .name-1 {
                font-weight: 500;
                color: #999999;
                font-size: 12px;
            }

            .name-2 {
                font-size: 15px;
                font-weight: bold;
                color: #144713;
            }

            .btn {
                font-size: 13px;
                font-weight: 500;
                color: #FCFCFB;
                background-color: #23a263;
                border-radius: 50px;
                padding: 5px 13px 6px;
            }
        }

        .interests-list {
            padding: 0 10px 30px;
            position: relative;
            z-index: 5;
            min-height: calc(100vh - 400px);

            .interests-title {
                font-size: 16px;
                font-weight: bold;
                color: #232323;
                line-height: 23px;
                position: relative;
                width: fit-content;
                margin: 0 auto 24px;
                padding: 0 10px;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 6px;
                    width: 100%;
                    left: 0;
                    bottom: 2px;
                    background: linear-gradient(270deg, rgba(238, 248, 242, 0) 0%, #8EE7BE 54%, rgba(220, 243, 231, 0) 100%);
                }
            }

            .interests-item-box {
                background: linear-gradient(360deg, rgba(255, 255, 255, 0.73) 0%, #EBF7F1 100%);
                box-shadow: 0px 1px 4px 1px rgba(220, 220, 220, 0.22);
                border-radius: 5px;
                padding: 18px 15px;

                /deep/ .interests-item {
                    .interests-item-title {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 7px;

                        .interests-item-title_text {
                            font-size: 16px;
                            font-weight: bold;
                            color: #232323;
                            letter-spacing: 1px;
                            line-height: 23px;

                            span {
                                color: #5FC85C;
                            }
                        }

                        .interests-btn {
                            font-weight: 500;
                            color: #369867;
                            font-size: 13px;
                            border-radius: 30px;
                            border: 1px solid #AFD7C4;
                            padding: 4px 8px 4px 10px;
                            height: fit-content;
                            white-space: nowrap;
                            transform: translateY(-4px);
                            margin-left: 5px;

                            &:after {
                                content: '\e660';
                                font: normal normal normal 14px/1 vant-icon;
                            }
                        }
                    }

                    .interests-item-detail {
                        font-size: 13px;
                        font-weight: 500;
                        color: #666666;
                        line-height: 19px;
                    }

                    .interests-item-f {
                        gap: 10px 0;
                        margin-top: 14px;
                        flex-wrap: wrap;

                        .interests-w {
                            width: 25%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 37px;
                            }

                            span {
                                font-size: 12px;
                                margin-top: 11px;
                                font-weight: 500;
                                color: #666666;
                                line-height: 17px;
                            }
                        }
                    }
                }

                & + .interests-item-box {
                    margin-top: 10px;
                }

                &:nth-of-type(odd) {
                    background: linear-gradient(360deg, rgba(255, 255, 255, 0.73) 0%, #EBF2F7 100%);
                    box-shadow: 0px 1px 4px 1px rgba(220, 220, 220, 0.22);

                    /deep/ .interests-btn {
                        color: #2C7FD2 !important;
                        border-color: #BDCFE0 !important;
                    }
                }
            }
        }
    }

    .shab {
        position: fixed;
        right: 0;
        writing-mode: vertical-lr;
        background: #E6F3EE;
        border-radius: 7px 0px 0px 7px;
        bottom: 30%;
        z-index: 10;
        padding: 6px 10px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 4px;
        color: #144713;

        img {
            width: 13px;
            margin-bottom: 7px;
        }
    }
}

.kefu {
    padding: 40px 50px;
    box-sizing: border-box;
    text-align: center;
    font-size: 17px;
    line-height: 26px;
}

:deep(.detail-control) {
    height: 70vh;
    background: #EDF6F1;

    .detail-tab {
        display: flex;
        flex-direction: column;
        height: 100%;

        .van-tabs__nav {
            background-color: transparent;
        }

        .van-tab {
            font-size: 16px;
            font-weight: 500;
            color: #999999;
        }

        .van-tabs__wrap {
            padding-top: 10px;
        }

        .van-tab--active {
            font-size: 17px;
            font-weight: bold;
            color: #144713;
        }

        .van-tabs__line {
            background: #58B889;
            height: 4px;
            bottom: 19px;
        }

        .van-tabs__content {
            margin-top: 20px;
            flex: 1;
            height: 0;
        }

        .van-tab__pane {
            height: 100%;
        }
    }

    .module-title {
        font-size: 14px;
        font-weight: bold;
        color: #232323;
        line-height: 20px;

        letter-spacing: 1px;

        img {
            width: 19px;
            margin-right: 8px;
        }
    }

    .person-detail {
        margin: 0px 10px 0;
        background: #FAFAFA;
        border-radius: 8px 8px 0px 0px;
        padding: 19px 10px 40px;
        box-sizing: border-box;
        overflow: auto;
        height: 100%;

        .person-box {
            background: #F6F6F6;
            border-radius: 4px;
            padding: 20px 30px;
            margin-top: 16px;

            .detail-item {
                & + .detail-item {
                    margin-top: 30px;
                }

                .label {
                    font-size: 15px;
                    font-weight: 500;
                    color: #666666;
                    line-height: 21px;
                    width: 120px;
                }

                .value {
                    font-size: 15px;
                    font-weight: bold;
                    color: #232323;
                    line-height: 21px;
                }
            }
        }

    }

    .drug-box {
        height: 100%;
        margin: 0 10px;
        background: #FAFAFA;
        border-radius: 8px 8px 0px 0px;
        padding: 19px 10px 40px;
        box-sizing: border-box;
        overflow: auto;

        .drug-info {
            font-size: 14px;
            font-weight: 500;
            color: #666666;
            padding-left: 25px;
            margin-top: 5px;
        }

        .drug-table {
            margin-top: 15px;
            border-top: 4px solid #369867;
            border-radius: 4px 4px 0 0;

            .drug-tr {
                display: grid;
                grid-template-columns: 20% 30% 50%;
                font-size: 13px;
                font-weight: 500;
                color: #666666;
                line-height: 20px;

                &:nth-of-type(odd) {
                    background: #F6F6F6;
                }
            }

            .drug-header {
                font-size: 14px;
                font-weight: 500;
                color: #232323;
            }

            .drug-td {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 5px;
                line-height: 27px;
            }
        }
    }
}
</style>